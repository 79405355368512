import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { RoleBaseAccessControlService } from './role-base-access-control.service';

@Directive({
  selector: '[hesRbac]',
  standalone: true,
})
export class RbacDirective {
  private readonly templateRef = inject(TemplateRef);
  private readonly vc = inject(ViewContainerRef);
  private readonly rbacService = inject(RoleBaseAccessControlService);

  @Input({ required: true })
  set hesRbac(permissionId: number | undefined) {
    const shouldDisplay =
      permissionId === undefined ||
      this.rbacService.hasPermission(permissionId);
    this.updateView(shouldDisplay);
  }

  private updateView(shouldDisplay: boolean): void {
    if (shouldDisplay) {
      this.vc.createEmbeddedView(this.templateRef);
    } else {
      this.vc.clear();
    }
  }
}

@Directive({
  selector: '[hesRbacSome]',
  standalone: true,
})
export class RbacSomeDirective {
  private readonly templateRef = inject(TemplateRef);
  private readonly vc = inject(ViewContainerRef);
  private readonly rbacService = inject(RoleBaseAccessControlService);

  @Input({ required: true })
  set hesRbacSome(permissionId: number[] | undefined) {
    if (!permissionId?.length) {
      this.updateView(true);
      return;
    }
    const shouldDisplay =
      permissionId === undefined ||
      this.rbacService.hasSomePermission(permissionId);
    this.updateView(shouldDisplay);
  }

  private updateView(shouldDisplay: boolean): void {
    if (shouldDisplay) {
      this.vc.createEmbeddedView(this.templateRef);
    } else {
      this.vc.clear();
    }
  }
}
